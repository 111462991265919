// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Features from "../components/features"
import RDV from "../components/rdv"
import Download from "../components/download"

import incidents1 from "../images/incidents1.svg"
import incidents2 from "../images/incidents2.svg"
import incidents3 from "../images/incidents3.svg"
import annonces1 from "../images/annonces1.svg"
import annonces2 from "../images/annonces2.svg"
import messages1 from "../images/messages1.svg"
import messages2 from "../images/messages2.svg"

const Fonctionnalites = (props: PageProps) => (
  <Layout color="primary">
    <SEO title="Les fonctionnalités de Syndic +" />
    <Features noButton />
    <img src={incidents1}/>
    <img src={incidents2}/>
    <img src={incidents3}/>
    <img src={annonces1}/>
    <img src={annonces2}/>
    <img src={messages1}/>
    <img src={messages2}/>
    <RDV/>
    <Download/>
  </Layout>
)

export default Fonctionnalites
